<template>
  <div class="container" :style="`background-image:url(${data.bg})`">
    <slot name="title"></slot>
    <slot name="info"></slot>
    <div class="box" :class="{ reverse: reverse }" :id="data.name">
      <div class="content">
        <div class="content-title"><img :src="data.icon" />{{ data.name }}</div>
        <div class="content-info">{{ data.info }}</div>
        <div class="content-merit">
          <div v-for="(item, index) in data.merit" :key="index">
            <img :src="require('@/assets/gou.png')" />
            <div class="merit-t">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="image"><img :src="data.image" /></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
@mixin searchWidth($searchWidth) {
  .container {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .box {
    margin: auto;
    display: flex;
    justify-content: space-between;

    @if ($searchWidth<1100) {
      padding: 3.2rem 0.8rem;
      flex-direction: column-reverse;
    } @else {
      max-width: 13.2rem;
      padding: 0.6rem 0;
      &.reverse {
        flex-direction: row-reverse;
      }
    }
  }

  .content,
  .image {
    flex-shrink: 0;

    @if ($searchWidth<1100) {
      width: 100%;
    } @else {
      width: 50%;
      max-width: 6.1rem;
    }
  }

  .content {
    .content-title,
    .content-info,
    .content-merit {
      margin: auto;

      @if ($searchWidth<1100) {
        // width: 45%;
      } @else {
        width: 4.49rem;
      }
    }

    .content-title {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;

      @if ($searchWidth<1100) {
        font-size: 1rem;
        margin-top: 1rem;

        img {
          width: 1rem;
          margin-right: 0.4rem;
        }
      } @else {
        font-size: 0.3rem;

        img {
          width: 0.4rem;
          margin-right: 0.2rem;
        }
      }
    }

    .content-info {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      @if ($searchWidth<1100) {
        line-height: 1.1rem;
        font-size: 0.7rem;
        margin: 0.35rem auto 1.25rem;
      } @else {
        line-height: 0.25rem;
        font-size: 0.16rem;
        margin: 0.19rem auto 0.36rem;
      }
    }

    .content-merit {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;

      @if ($searchWidth<1100) {
        line-height: 1.25rem;
        font-size: 0.7rem;
      } @else {
        line-height: 0.25rem;
        font-size: 0.16rem;
      }

      > div {
        display: flex;
        align-items: flex-start;
        @if ($searchWidth<1100) {
          margin-bottom: 0.25rem;
        } @else {
          margin-bottom: 0.1rem;
        }
      }

      img {
        @if ($searchWidth<1100) {
          width: 0.75rem;
          margin-right: 0.4rem;
          margin-top: 0.25rem;
        } @else {
          width: 0.2rem;
          margin-right: 0.2rem;
        }
      }
    }
  }

  .image {
    img {
      width: 100%;
      display: block;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
